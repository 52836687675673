import { useDispatch, useSelector } from 'react-redux';
import { KTIcon } from '../../../../../../../_metronic/helpers'
import { setBookingModalStatus, setEventModalStatus, setForumModalStatus, setNewsModalStatus, setPlanModalStatus, setRoomModalStatus, setSubscriptionModalStatus, setTaskModalStatus, setUserModalStatus } from '../../../../../../../redux/features/shared/sharedSlice';

const UsersListToolbar = () => {
  const sharedActions = useSelector((state: any) => state.sharedActions);
  const dispatch = useDispatch();
  const openAddUserModal = () => {

    switch (sharedActions.id) {
      case 'User':
        dispatch(setUserModalStatus(true))
        break;
      case 'Event':
        dispatch(setEventModalStatus(true))
        break;
      case 'Booking':
        dispatch(setBookingModalStatus(true))
        break;
      case 'Room':
        dispatch(setRoomModalStatus(true))
        break;
      case 'Task':
        dispatch(setTaskModalStatus(true));
        break;
      case 'Forum':
        dispatch(setForumModalStatus(true));
        break;
      case 'Newsletter':
        dispatch(setNewsModalStatus(true));
        break;
      case 'Subscription':
        dispatch(setSubscriptionModalStatus(true));
        break;
      case 'Plan':
        dispatch(setPlanModalStatus(true));
        break;
      default:
    }
  }

  return (
    <>
      {(sharedActions.id === "User" || sharedActions.id === "Event" || sharedActions.id === "Booking"
        || sharedActions.id === "Room" || sharedActions.id === "Task" || sharedActions.id === "Forum"
        || sharedActions.id === "Newsletter"|| sharedActions.id === "Subscription"|| sharedActions.id === "Plan"
      ) && <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
          <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
            <KTIcon iconName='plus' className='fs-2' />
            Add {sharedActions.id}
          </button>
        </div>}
    </>
  )
}

export { UsersListToolbar }
