import { configureStore } from "@reduxjs/toolkit";
import { userList } from "./features/user/userSlice";
import { eventList } from "./features/event/eventSlice";
import logger from "redux-logger";
import {sharedSlice} from "./features/shared/sharedSlice"
import { bookingList } from "./features/booking/bookingSlice";
import { roomList } from "./features/room/roomSlice";
import { taskList } from "./features/task/taskSlice";
import { forumList } from "./features/forum/forumSlice";
import { newsLetterList } from "./features/news-letter/newsLetterSlice";
import { newsList } from "./features/news/newsSlice";
import { planList } from "./features/plan/planSlice";
import { subscriptionList } from "./features/subscription/subscriptionSlice";

export const store = configureStore({
  reducer: {
    userList: userList.reducer,
    eventList: eventList.reducer,
    bookingList: bookingList.reducer,
    roomList: roomList.reducer,
    sharedActions: sharedSlice.reducer,
    tasksList: taskList.reducer,
    forumList: forumList.reducer,
    newsLetterList: newsLetterList.reducer,
    newsList: newsList.reducer,
    planList: planList.reducer,
    subscriptionList: subscriptionList.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"],
      },
    })
      .concat(logger as any)
});


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
