export const LANG={
    ROLE_IS_REQUIRED: "Rolle ist erforderlich",
    ALREADY_SELECTED_IN_TEAM_B:"Bereits in Team B ausgewählt",
    ALREADY_SELECTED_IN_TEAM_A:"Bereits in Team A ausgewählt",
    DATE_CAN_NOT_BE_PAST:"Das Datum darf nicht vergangen sein",
    WELCOME_BACK:"Willkommen zurück",
    LOGIN_MSG: "Melden Sie sich an, um über die neuesten Nachrichten, Spiele und exklusiven Inhalte auf dem Laufenden zu bleiben.",
    PASSWORD: "Passwort",
    REMEMBER_PASSWORD:"Passwort merken",
    FORGOT_PASSWORD:"Passwort vergessen",
    LOG_IN:"Einloggen",
    HAVE_NOT_ACCOUNT:"Sie haben noch kein Konto?",
    REGISTER:"Registrieren!",
    REGISTER_MSG: "Treten Sie jetzt bei, um mit Fans in Kontakt zu treten, auf exklusive Inhalte zuzugreifen und auf dem Laufenden zu bleiben!",
    BACK:"Zurück",
    JOIN: "Verbinden",
    SELECT:"Wählen",
    LAST_CLUB: "Letzter Club",
    FIRST_NAME:"Vorname",
    LAST_NAME:"Nachname",
    STREET:"Straße",
    HOUSE_NO:"Hausnummer",
    ZIP_CODE:"PLZ",
    CITY:"Stadt",
    BIRTHDAY:"Geburtstag",
    BIRTHPLACE_CITY:"Geburtsort",
    BIRTHPLACE_COUNTRY:"Geburtsland",
    NATIONALITY:"Nationalität",
    PHONE:"Telefon",
    FIRSTNAME_PARENTS:"Vorname (Erziehungsberechtigter / Eltern)",
    LASTNAME_PARENTS:"Nachname (Erziehungsberechtigter / Eltern)",
    EMAIL_PARENTS:"Email (Erziehungsberechtigter / Eltern)",
    PHONE_PARENTS:"Telefon (Erziehungsberechtigter / Eltern)",
    RELATION:"Beziehung",
    GENDER:"Geschlecht",
    BANK_NAME:"Name des Kreditinstitutes",
    IBAN_BANK_ACCOUNT:"IBAN",
    BIC_BANK_CODE:"BIC",
    ACCOUNT_HOLDER:"Name des Kontoinhabers",
    PHOTO:"Foto",
    ATTACHMENTS:"Anhänge",
    CONTINUE:"Weiter",
    HAVE_AN_ACCOUNT:"Haben Sie ein Konto?",
    SIGN_IN:"Anmelden",
    PLEASE_CHOOSE:"Bitte auswählen",
    REGISTER_PARENT_MSG:"Um fortzufahren, müssen Sie die Zustimmung eines Elternteils oder Erziehungsberechtigten vorlegen.",
    SELECT_PLAN:"Wählen Sie Planen aus",
    REGISTER_PLAN_MSG:"Wählen Sie den Plan, der Ihren Bedürfnissen am besten entspricht, und genießen Sie exklusive Vorteile.",
    INDIVIDUAL_YOUTH_MEMBERSHIP:"Individuelle Jugendmitgliedschaft",
    FAMILY_PLAN:"Familienplan", 
    SPECIAL_PLAN:"Sonderpläne",
    PRIVACY_HEADING:"Einwilligung zur Datenschutzerklärung",
    PRIVACY_MSG:"Mit dem Absenden dieses Formulars bestätige ich, dass ich die Datenschutzerklärung gelesen und verstanden habe. Ich willige ein, dass meine personenbezogenen Daten gemäß der Datenschutzerklärung verarbeitet werden",
    PRIVACY_POLICY:"Datenschutzrichtlinie",
    DATA_POCILY_HEADING:"Einwilligung zur Verwendung der Daten zu Werbezwecken",
    DATA_POLICY_MSG:"Hiermit erkläre ich mich einverstanden, dass meine Kontaktdaten (z.B. E-Mail-Adresse und Telefonnummer) zu Werbezwecken, wie dem Erhalt von Informationen zu Produkten, Dienstleistungen oder Veranstaltungen des Unternehmens, verwendet werden. Diese Einwilligung kann ich jederzeit mit Wirkung für die Zukunft widerrufen, indem ich eine entsprechende Mitteilung an [Kontaktadresse einfügen] sende.",
    TERMS_AND_CONDITION:"AGBs (Satzung)",
    I_AGREE_TO:"Ich stimme zu",
    UPLOAD_DOCUMENT:"Dokument hochladen",
    APPLICATION_FOR_MATCH_PERMISSIONS:"Antrag auf Spielerlaubnis (Bitte Anfrag auf Spielerlaubnis, downloaden,  ausfüllen und hochladen)",
    APPLICATION_FOR_CLUB_TRANSFER:"Antrag auf Vereinswechsel (Bitte Anfrag auf Spielerlaubnis, downloaden,  ausfüllen und hochladen)",
    BIRTH_CERTIFICATE:"Kopie der Geburtsurkunde",
    DOCTOR_CERTIFICATE:"Ärztliches Attest",
    RESIDENCE_CERTIFICATION:"Nachweis über den Wohnsitz in der Bundesrepublik Deutschland (Bestätigung der Meldebehörde)",
    PLAYER_PARENTS_DECLARATION:"Zusatzformular to_players_parents zu finden im Download-Bereich unter www.hfv-online.de",
    COPY_OF_PASSPORT:"Kopie des Personalausweises / Reisepasses / Ausweisdokument (Aufenthaltsgestattung /- nehmigung / -erlaubnis)",
    APPLICATION_ATTACHMENT_ARGETINA:"Ausgefülltes Formblatt zu finden im Download-Bereich unter www.hfv-online.de",
    APPLICATION_ATTACHMENT_ISTUPNICA:"Abmeldung beim Verein (Istupnica)",
    APPLICATION_ATTACHMENT_BRISOVNICA:"Abmeldebestätigung von dem zuständigen Regionalverband (Brisovnica)",
    REGISTRATION_SUCCESSFULLY_DONE:"Registrierung erfolgreich abgeschlossen.",
    LOGIN_SUCCESSFULLY:"Anmeldung erfolgreich",
    USER_PROFILE:"Benutzerprofil",
    DASHBAORD:"Armaturenbrett",
    EVENTS:"Veranstaltungen",
    FRIENDS:"Freunde",
    PROFILE:"Profil",
    HOME:"Home",
    ACCOUNT_SETTING:"Kontoeinstellung",
    CHANGE_PASSWORD:"Kennwort ändern",
    SUBSCRIPTION:"Abonnement",
    SUBSCRIPTION_UPDATED_SUCCESSFULLY:"Abonnement erfolgreich aktualisiert.",
    HELP_AND_SUPPORT:"Hilfe und Support",
    EDIT_ACCOUNT_DETAILS:"Kontodetails bearbeiten",
    UPLOAD_PHOTO:"Foto hochladen",
    PERSONAL_INFORMATION:"Persönliche Informationen",
    ADDRESS:"Adresse",
    BANK_DETAIL:"Bankverbindung",
    DOCUMENTS:"Unterlagen",
    UPDATE:"Aktualisieren",
    PASSWORD_AND_SECURITY:"Passwort und Sicherheit",
    UPDATE_YOUR_PASSWORD:"Aktualisieren Sie Ihr Passwort",
    ENTER_OLD_PASSWORD: "Geben Sie das alte Passwort ein",
    ENTER_NEW_PASSWORD:"Geben Sie ein neues Passwort ein",
    ENTER_CONFIRM_PASSWORD: "Geben Sie das Bestätigungspasswort ein",
    PASSWORD_CHANGED_SUCCESSFULLY:"Passwort erfolgreich geändert.",
    SETTINGS:"Einstellungen",
    LOGOUT:"Abmelden",
    CONTACT_US:"Kontaktieren Sie uns",
    ENTER_REGISTERED_EMAIL:"Geben Sie die registrierte E-Mail-Adresse ein",
    SUBMIT:"Einreichen",
    INVALID_EMAIL_ADDRESS:"Ungültige E-Mail-Address.",
    OTP_EXPIRED:"Warten Sie auf die Genehmigung des Administrators",
    ACCOUNT_VERIFICATION:"Kontobestätigung",
    INVALID_LINK:"Ungültiger Link",
    ACCOUNT_VERIFIED_SUCCESSFULLY:"Konto erfolgreich verifiziert",
    LINK_EXPIRED: "Link abgelaufen",
    USER_NOT_FOUND: "Benutzer nicht gefunden",
    ENTER_EMAIL_ADDRESS:"Geben Sie die Email ein",
    EMAIL_IS_REQUIRED: "Email ist erforderlich",
    EMAIL_ALREADY_EXISTS: "Email existiert bereits",
    PASSWORD_IS_REQUIRED: "Password ist erforderlich",
    CONFIRM_PASSWORD_IS_REQUIRED: "Bestätigen password ist erforderlich",
    PASSWORD_MUST_MATCH:"Das Passwort muss übereinstimmen",
    PLEASE_ADD_VALID_EMAIL:"Bitte fügen Sie eine gültige Email hinzu",
    FIELD_IS_REQUIRED: "Feld ist erforderlich",
    FIRSTNAME_IS_REQUIRED: "Vorname ist erforderlich",
    LASTNAME_IS_REQUIRED: "Nachname ist erforderlich",
    BIRETHDATE_IS_REQUIRED: "Geburtsdatum ist erforderlich",
    STREET_IS_REQUIRED: "Straße ist erforderlich",
    HOUSE_NO_IS_REQUIRED: "Hausnummer ist erforderlich",
    ZIP_CODE_IS_REQUIRED: "PLZ ist erforderlich",
    CITY_IS_REQUIRED: "Stadt ist erforderlich",
    MAXIMUM_LIMIT_HUNDRED_CHAR:"Die Höchstgrenze beträgt 13 Zeichen",
    MAXIMUM_LIMIT_BIC_CHAR:"Die Höchstgrenze beträgt 11 Zeichen",
    MINIMUM_LIMIT_BIC_CHAR:"Die Mindestbeschränkung beträgt 8 Zeichen",
    MINIMUM_LIMIT_PHONE_CHAR:"Die Mindestbeschränkung beträgt 10 Zeichen",
    ZIPCODE_MUST_BE_FIVE_CHAR:"Die Postleitzahl muss 5-stellig sein",
    MEMBER_EMAIL:"E-Mail des Mitglieds",
    NAME:"Name",
    SIBLING_DETAILS:"Geschwisterdetails",
    ADD_SIBLING:"Geschwister hinzufügen",
    INVALID_MEMBER_ID: "Ungültige Mitglieds-Email",
    CONFIRM_PASSWORD:"Passwort bestätigen",
    PRIVATE:"Privat",
    PUBLIC:"öffentlich",
    MEMBER_ID:"Mitglieds-ID",
    JOINED:"Beigetreten",
    SEASON:"Jahreszeit",
    CONTACT:"Kontakt",
    EDIT_PROFILE:"Profil bearbeiten",
    ADD_NEW_FRIENDS:"Füge neue Freunde hinzu",
    FRIEND_REQUESTS: "Freundschaftsanfragen",
    FRIEND:"Freund",
    SEND_FRIEND_REQUESTS:"Freundschaftsanfrage senden",
    ACCEPT:"Akzeptieren",
    DECLINE:"Ablehnen",
    SEARCH_FRIENDS:"Suche Freunde",
    VIEW_ONLY:"Nur anzeigen",
    SELECT_THE_ROLE_TO_CONTINUE:"Wählen Sie die Rolle aus, um fortzufahren",
    MEMBER:"Mitglied",
    TRAINER:"Trainer",
    SPONSOR:"Sponsor",
    TASK:"Aufgabe",
    MY_TEAM:"Mein Team",
    TOTAL_TEAM_MEMBER: "Gesamtes Teammitglied",
    ALL_MEMBERS:"Alle Mitglieder",
    VIEW_DETAILS:"Details anzeigen",
    TEAM:"Team",
    CREATE_NEW_TEAM:"Neues Team erstellen",
    ENTER_TEAM_NAME:"Geben Sie den Teamnamen ein",
    SELECT_MEMBER:"Wählen Sie Mitglied aus",
    SEARCH_MEMBER:"Mitglieder suchen",
    CHOOSE_SELECTED_MEMBER:"Wählen Sie das ausgewählte Mitglied aus",
    SELECTED_MEMBER:"Ausgewähltes Mitglied",
    CREATE_TEAM:"Team erstellen",
    TEAM_CREATED_SUCCESSFULLY:"Team erfolgreich erstellt.",
    EDIT_MEMBER:"Mitglied bearbeiten",
    UPDATED_SUCCESSFULLY:"Erfolgreich aktualisiert",
    CREATE_NEW_EVENT:"Neue Veranstaltung erstellen",
    NEW_EVENT:"Neue Veranstaltung",
    EVENT_DETAILS:"Veranstaltungsdetails",
    ASSIGNED_TRAINER:"Zugewiesener Trainer",
    BLOOD_GROUP:"Blutgruppe",
    PROFILE_UPDATED_SUCCESSFULLY:"Profil erfolgreich aktualisiert.",
    NO_FRIENDS_AVAILABLE:"Keine Freunde verfügbar",
    DESCRIPTION:"Beschreibung",
    MEMBERS_DETAIL:"Mitgliederdetails",
    EVENT_TYPE:"Ereignistyp",
    EVENT_DURATION:"Veranstaltungsdauer",
    ROOM_NAME:"Raumname",
    TIME:"Zeit",
    TIME_DURATION:"Zeitdauer",
    EVENT_DESCRIPTION:"Beschreibung der Veranstaltung",
    MAXIMUM_PARTICIPANTS:"Maximale Teilnehmerzahl",
    LOCATION:"Standort",
    SELECT_MEMBERS_TEAM_A:"Wählen Sie Mitgliedsteam A aus",
    SELECT_MEMBERS_TEAM_B:"Wählen Sie Mitgliedsteam B aus",
    TEAM_AND_PARTICIPANTS_TYPE:"Teams/Einzelteilnehmer",
    EVENT:"Ereignis",
    PARTICIPANTS:"Teilnehmer",
    EVENT_NOT_FOUNDS:"Veranstaltungen nicht gefunden",
    EVENT_REQUEST_ACCEPTED_SUCCESSFULLY:"Ereignisanfrage erfolgreich angenommen",
    EVENT_REQUEST_REJECTED_SUCCESSFULLY:"Ereignisanfrage erfolgreich abgelehnt",
    ALERTS:"Alarm",
    EVENT_REQUEST:"Veranstaltungsanfrage",
    GROUND_NAME:"Bodenname",
    DURATION:"Dauer",
    DATE:"Datum",
    ACTION:"Aktion",
    DIGITAL_CARD:"Digital Card",
    AGE:"Alter",
    YEARS:"Jahre",
    MEMBERSHIP_EXPIRE:"Mitgliedschaft läuft aus",
    APPROVED:"Genehmigt!",
    UNDER_REVIEW:"Wird überprüft",
    NOTIFICATIONS:"Benachrichtigungen",
    NO_DATA:"Keine Daten",
    ongoingTask: "Laufende Aufgabe",
    task_name:"Aufgabenname",
    assign_date:"Datum zuweisen",
    description:"Beschreibung",
    status:"Status",
    comment:"Kommentare/Feedback",
    deadline:"Frist",
    action:"Aktion",
    details:"Details",
    no_ongoing_task_available:"Keine laufenden Aufgaben verfügbar",
    completed_task:"Abgeschlossene Aufgabe",
    no_completed_tasks_available:"Keine abgeschlossenen Aufgaben verfügbar",
    inprogress:"Im Gange",
    complete: "Vollständig",
    REQUEST_SENDED:"Anfrage gesendet",
    ADD:"Hinzufügen",
    USER:"Benutzer",
    CHANGE:"Ändern",
    ROLE:"Rolle",
    CANCEL:"Stornieren",
    LIMIT_IS_EXCEEDED_FORM_MAX_LIMIT:"Der Grenzwert wurde vom maximalen Grenzwert überschritten.",
    MEMBERS_IS_REQUIRED:"Mitglieder sind erforderlich.",
    START_DATE:"Startdatum",
    END_DATE:"Enddatum"
}