import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { setEventModalStatus, setFiles, setFormDetails, setRoomModalStatus} from '../../../../redux/features/shared/sharedSlice';
import { Field, FormikProvider, useFormik } from 'formik';
import { Form } from 'react-bootstrap';
import FieldInputText from '../common/InputFeilds/InputTextField';
import * as Yup from "yup";
import { REQUIRED, ROOM_TYPES } from '../../../../utils/const';
import Select from 'react-select';
import { addRoomDetails, updateRoomDetails } from '../../../../redux/features/room/_roomAction';

function RoomModal() {
  const dispatch: any = useDispatch();
  const sharedActions: any = useSelector((state: any) => state.sharedActions);

  const eventFormValidation = Yup.object().shape({
    roomName: Yup.string().required(REQUIRED),
    roomType: Yup.string().required(REQUIRED),
    description: Yup.string(),
  });
  
  const formIntialValues = {
    roomName: sharedActions.formDetails.roomName || '',
    roomType: sharedActions.formDetails.roomType || '',
    description: sharedActions.formDetails.description || '',
  };


  const partnerFormik = useFormik({
    initialValues: formIntialValues,
    validationSchema: eventFormValidation,
    onSubmit: (values: any, { resetForm }) => {
      values.selectedPage = sharedActions.selectedPage
      if(sharedActions?.formDetails?._id){
        dispatch(updateRoomDetails({...values,id: sharedActions.formDetails?._id }));
      }else{
        dispatch(addRoomDetails(values));
      }
      dispatch(setFormDetails({}))
      setTimeout(() => {
        dispatch(setRoomModalStatus(false))
        resetForm();
      }, 500);
    },
  });

  const closeModal = () => {
    dispatch(setRoomModalStatus(false))
    dispatch(setFormDetails({}))
    dispatch(setFiles([]))
  }

  const handleSelect = (selectedList: any, selectedItem: any) => {
    partnerFormik.setFieldValue("role", selectedList);
  };

  return (
    <>
      <Modal backdrop="static" size='lg' show={sharedActions.roomDetailsModal} onHide={closeModal} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>{!sharedActions.formDetails._id ? 'Add' : 'Update'} Room</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormikProvider value={partnerFormik}>
            <Form onSubmit={partnerFormik.handleSubmit} method='POST'>
              <div className="row">
                <div className="col-sm-12  col-md-6 mb-6">
                  <Form.Group>
                    <Field
                      name="roomName"
                      validate={eventFormValidation}
                      type="text"
                      isRequired={true}
                      label="Room Name"
                      component={FieldInputText}
                      placeholder="Enter Room Name"
                      maxLength={25}
                    />
                  </Form.Group>
                </div>
                <div className="col-sm-12 col-md-6 mb-6">
                  <Form.Group>
                    <Form.Label>
                      Room Type <span>*</span>
                    </Form.Label>
                    <Select
                      className='custom-select-box'
                      isSearchable={false}
                      value={ROOM_TYPES.find((g)=>g.value === partnerFormik.values?.roomType)}
                      name="roomType"
                      placeholder='Select Room Type'
                      onChange={(v) => {
                        partnerFormik.setFieldValue("roomType", v?.value);
                      }}
                      options={ROOM_TYPES}
                    />
                  </Form.Group>
                  {partnerFormik.errors.roomType && partnerFormik.touched.roomType && (
                    <div className="formik-errors text-danger">{`${partnerFormik.errors.roomType}`}</div>
                  )}
                </div>
              </div>
              <div className="row">
              <div className="col-sm-12  col-md-12 mb-6">
                  <Form.Group>
                    <Field
                      as={'textarea'}
                      name="description"
                      validate={eventFormValidation}
                      label="Description"
                      component={FieldInputText}
                    />
                  </Form.Group>
                </div>
              </div>
              <Modal.Footer>
                <Button
                  type="button"
                  variant="secondary"
                  className="primaryBtn"
                  onClick={closeModal}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="primaryBtn active"
                >
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          </FormikProvider>
        </Modal.Body>
      </Modal>
    </>
  );
}

export { RoomModal };