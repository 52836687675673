import { FC, lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import EventPage from "../modules/apps/events/EventPage";
import BookingPage from "../modules/apps/bookings/BookingPage";
import RoomPage from "../modules/apps/rooms/RoomPage";

const PrivateRoutes = () => {
  const UserPage = lazy(() => import("../modules/apps/users/UserPage"));
  const TaskPage = lazy(() => import("../modules/apps/tasks/TaskPage"));
  const ForumPage = lazy(() => import("../modules/apps/forum/ForumPage"));
  const NewsLetterPage = lazy(() => import("../modules/apps/news-letters/NewsLetterPage"));
  const SubscriptionPage = lazy(() => import("../modules/apps/subscriptions/SubscriptionPage"));

  return (
    <>
      <Routes>
        <Route element={<MasterLayout />}>
          <Route path="auth/*" element={<Navigate to="/users" />} />
          <Route
            path="users/*"
            element={
              <SuspensedView>
                <UserPage />
              </SuspensedView>
            }
          />
          <Route path="*" element={<Navigate to="/users/list" />} />

          <Route
            path="events/*"
            element={
              <SuspensedView>
                <EventPage />
              </SuspensedView>
            }
          />
          <Route path="*" element={<Navigate to="/events/list" />} />

          <Route
            path="bookings/*"
            element={
              <SuspensedView>
                <BookingPage />
              </SuspensedView>
            }
          />
          <Route path="*" element={<Navigate to="/events/list" />} />

          <Route
            path="rooms/*"
            element={
              <SuspensedView>
                <RoomPage />
              </SuspensedView>
            }
          />
          <Route path="*" element={<Navigate to="/rooms/list" />} />

          <Route
            path="tasks/*"
            element={
              <SuspensedView>
                <TaskPage />
              </SuspensedView>
            }
          />
          <Route path="*" element={<Navigate to="/tasks/list" />} />

          <Route
            path="forums/*"
            element={
              <SuspensedView>
                <ForumPage />
              </SuspensedView>
            }
          />
          <Route path="*" element={<Navigate to="/forums/list" />} />

          <Route
            path="news-letters/*"
            element={
              <SuspensedView>
                <NewsLetterPage />
              </SuspensedView>
            }
          />
          <Route path="*" element={<Navigate to="/news-letters/list" />} />

          <Route
            path="subscriptions/*"
            element={
              <SuspensedView>
                <SubscriptionPage />
              </SuspensedView>
            }
          />
          <Route path="*" element={<Navigate to="/subscriptions/list" />} />

        </Route>
      </Routes>
    </>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
